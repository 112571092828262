const ProductType = {
  none: 0,
  vegetarian: 1,
  non_vegetarian: 2

};

import i18n from '@/libs/i18n';

const ProductTypeTranslations = [

  {
    id: 0,
    name: i18n.t('products.type.none')
  },
  {
    id: 1,
    name: i18n.t('products.type.vegetarian')
  },
  {
    id: 2,
    name: i18n.t('products.type.non_vegetarian')
  }


];

function filterProductType(id) {
  return ProductTypeTranslations.find((item) => item.id === id);
}


export { ProductType, ProductTypeTranslations, filterProductType };
