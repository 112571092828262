<template>
  <div class='wameed-dashboard-page-content'>


    <section class='wameed-dashboard-page-content_body products_create'>

      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'>
          <b-card no-body class='wameed-card'>
            <b-card-body
              class='position-relative justify-content-between px-5'
            >

              <div class='d-flex  align-items-start ' style='gap: 16px'>
                <wameed-switch
                  :checked='advance_detail'
                  @onChange='advance_detail = !advance_detail'
                />
                <div>
                  <div class='text-med-18 text-font-main unset-line-height'>
                    {{ $t('products.advanced_detail_title') }}
                  </div>
                  <div class='text-reg-16 unset-line-height'>
                    {{ $t('products.advanced_detail_body') }}
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <wameed-form @onSubmit='submit'>
        <div slot-scope='{ invalid }'>
        <b-row class='px-2'>
          <b-col lg='12' md='12' class='px-0'>
            <b-card no-body class='wameed-card'>
                <b-card-header class='align-items-start tab-title mb-4'>
                  <h5 class='text-med-20 text-font-main mb-0'>
                    {{ $t('products.info') }}
                  </h5>
                </b-card-header>

                <b-card-body
                  class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>

                    <b-col cols='12' md='4'>
                      <div @click='$refs.imageinput.click()'>
                        <text-input
                          id='main-image'
                          v-model='form.image'
                          readonly
                          name='image'
                          :label="$t('products.form.image')"
                          :placeholder="$t('form.image.placeholder')"
                          is-append
                          append-class='append-btn'
                          :append-text="$t('form.image.browse')"
                        />
                      </div>
                      <input
                        ref='imageinput'
                        type='file'
                        accept='image/png, image/jpeg'
                        style='display: none'
                        @change='uploadImageAction($event)'
                      />
                    </b-col>

                    <b-col cols='12' md='4' v-if='showAdvancedInput'>
                      <div @click='$refs.subimageinput.click()'>
                        <text-input
                          id='main-image'
                          v-model='form.sub_image'
                          readonly
                          name='sub_image'
                          :label="$t('products.form.sub_image')"
                          :placeholder="$t('form.image.placeholder')"
                          is-append
                          append-class='append-btn'
                          :append-text="$t('form.image.browse')"
                        />
                      </div>
                      <input
                        ref='subimageinput'
                        type='file'
                        accept='image/png, image/jpeg'
                        style='display: none'
                        @change='uploadImageAction($event,"upload",true)'
                      />
                    </b-col>


                  </div>
                  <div class='d-flex flex-wrap'>


                    <b-col cols='12' md='4'>
                      <text-input
                        id='name'
                        v-model='form.name'
                        :label="$t('products.form.name')"
                        rules='required'
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        v-model='form.category'
                        :label="$t('products.form.category')"
                        :placeholder="$t('form.category.placeholder')"
                        variant='disable'
                        :items='getCategories'
                        class='wameed_dropdown'
                        title='name'
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <TextInput
                        v-model='form.price'
                        rules='required'
                        field-classes='w-100'
                        :label="$t('products.form.price')"
                        :placeholder="$t('form.text.placeholder')"
                        is-append
                        type='number'
                        :append-text="$t('common.rial')"
                      />
                    </b-col>


                  </div>

                  <div class='d-flex flex-wrap' v-if='showAdvancedInput'>
                    <b-col cols='12' md='4'>
                      <div class='text-font-main mb-4'>
                        {{ $t('products.form.type') }}
                      </div>
                      <div class='d-flex mb-5' style='gap:16px'>
                        <button type='button' class='discount_on_btn'
                                style='width: 33%; padding: 10px'
                                v-for='type in productTypeList'
                                :key='type.id'
                                :class="[type.id === form.type?'active':'']"
                                @click='()=>changeProductType(type)'
                        >
                          {{ type.name }}
                        </button>
                      </div>
                    </b-col>
                    <b-col cols='12' md='4'>
                      <TextInput
                        v-model='form.calories'
                        rules='required'
                        field-classes='w-100'
                        :label="$t('products.form.calories')"
                        :placeholder="$t('form.text.placeholder')"
                        type='number'
                      />
                    </b-col>
                  </div>


                  <div class='d-flex flex-wrap' v-if='showAdvancedInput'>

                    <b-col cols='12' md='12'>
                      <wameed-text-area
                        v-model='form.description'
                        :label="$t('products.form.desc')"
                        :placeholder="$t('form.text.placeholder')"
                        rows='5'
                      />
                    </b-col>
                  </div>


                </b-card-body>
            </b-card>
          </b-col>


        </b-row>


        <additional-data v-if='showAdvancedInput' ref='additional_options' :values='form.options'/>

        <div class='px-2'>

          <b-card no-body class='wameed-card'>
            <b-card-footer class='px-4 py-3' style='border-color: white'>
              <div class='d-flex flex-wrap justify-content-between'>
                <div class='px-4 py-3'>
                  <wameed-btn
                    classes='  text-med-14 text-white rounded-10'
                    :title="isUpdate?$t('products.update_btn'):$t('products.new_btn')"
                    :disabled='invalid'
                    type='submit'
                    variant='main'
                  />
                </div>

                <div class='px-4 py-3'>
                  <wameed-btn
                    classes='  text-med-14 text-font-secondary rounded-10 '
                    :title="$t('common.cancel')"
                    type='button'
                    variant='gray'
                    @onClick='goBack'
                  />
                </div>
              </div>
            </b-card-footer>
          </b-card>
        </div>
  </div>
      </wameed-form>
    </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import AdditionalData from '@/views/pages/users/vendors/details/products/components/additionalData';
import { ProductTypeTranslations } from '@/enums/ProductType.enum';

export default {
  components: {
    AdditionalData,
    TextInput,
    WameedTextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      vendor_id: '',
      advance_detail: false,

      productTypeList: ProductTypeTranslations,
      form: {
        name: '',
        price: '',
        calories: '',
        image: '',
        category: '',
        category_id: '',
        description: '',
        type: '',
        options: []
      }


    };
  },
  computed: {
    ...mapGetters({
      getCategories: 'vendor/products/getCategories',
      detail: 'vendor/products/getDetail'
    }),
    isUpdate() {
      return (this.$route.name === 'users-vendors-products-update' && this.$route.params.prod_id);
    },

    showAdvancedInput() {
      return this.advance_detail === true;
    }
  },


  created() {

    this.vendor_id = this.$route.params.id;

    if (this.$route.name === 'users-vendors-products-update' && this.$route.params.prod_id) {
      this.$store.dispatch('vendor/products/detail', {
        id: this.$route.params.prod_id,
        vendor_id: this.vendor_id
      }).then(() => {
        this.form = this.detail;
        this.form.category = this.getCategories.filter((cat) => cat.id === this.form.category_id)[0];
      });
    } else {
      this.$store.dispatch('vendor/products/unsetDetail');
    }
    this.$store.dispatch('vendor/products/getCategories', { vendor_id: this.vendor_id });

  },
  methods: {
    changeProductType(type) {
      this.form.type = type.id;
    },

    uploadImageAction(event, type = 'upload', subImage = false) {
      this.$store.commit('loadingStart', null, { root: true });
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      if (this.dropped == 0) files = event;
      else files = event.currentTarget.files;

      if (!files.length) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);

        this.$store.dispatch('utilities/uploadFiles', formData).then(response => {
          if (subImage) {
            this.form = {
              ...this.form,
              sub_image: response
            };
          } else {
            this.form = {
              ...this.form,
              image: response
            };
          }

          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    },


    goBack() {
      this.$router.push({ name: 'products' });
    },

    submit() {
      this.form.vendor_id = this.vendor_id;
      if (this.$refs.additional_options) {
        this.form.options = Object.values(this.$refs.additional_options.options);
      }
      this.form.category_id = this.form.category.id;

      if (this.$route.name === 'users-vendors-products-update' && this.$route.params.prod_id) {
        this.form.id = this.$route.params.prod_id;
        this.$store.dispatch('vendor/products/update', this.form);
      } else {
        this.$store.dispatch('vendor/products/create', this.form);
      }

    }
  }

}
;
</script>
